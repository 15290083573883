<template>
  <div class="">
    <!-- <header-page :header="headers"></header-page> -->
    <v-container>
      <v-row>
        <v-col v-if="loaderdata === false">
          <v-card tile flat class="px-4 mb-4">
            <v-card-title>
              <v-icon @click="backToPrevious" class="mr-10" color="secondary">{{ icons.mdiKeyboardBackspace }}</v-icon>
              <!-- <v-icon class="mr-3">{{ icons.mdiTrashCan }}</v-icon> -->
              <!-- <v-icon class="">{{ icons.mdiPrinter }}</v-icon> -->
              <v-spacer></v-spacer>
              <v-card-subtitle class="text-md pa-0">
                <v-icon size="13" class="secondary--text mr-1">{{ icons.mdiAlertCircle }}</v-icon>
                <span class="secondary--text">Published at: {{ jobitem.created_at }}</span>
              </v-card-subtitle>
            </v-card-title>
            <v-card-title>
              <span>{{ jobitem.title }}</span>
            </v-card-title>
            <v-card-subtitle>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiScatterPlot }}</v-icon>
                <span>Domain: </span>
                <span>{{ jobitem.domain }}</span>
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiMapMarkerOutline }}</v-icon>
                <span>Location: </span>
                <span>{{ jobitem.location }}</span>
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                <span>Term duration: </span>
                <span> {{ jobitem.duration }}</span>
              </div>
              <div>
                <v-icon small class="mr-1">{{ icons.mdiTargetAccount }}</v-icon>
                <span>Language: </span>
                <span> {{ jobitem.joblang }}</span>
              </div>
            </v-card-subtitle>
            <v-card-text class="d-flex align-center">
              <v-avatar size="45" class="primary mr-4" tile>
              </v-avatar>
              <div class="mx-4 d-bloc">
                <h3>
                  <span class="primary--text">
                    {{ jobitem.enterprise.name }}
                  </span>
                </h3>
                <!-- <a :href="jobitem.website" target="_blank">{{ jobitem.website }}</a> -->
              </div>
              <v-spacer></v-spacer>
              <v-icon size="13" class="error--text mr-1">{{ icons.mdiCloseCircle }}</v-icon>
              <span class="error--text">Expiring on: {{ jobitem.expire_at }}</span>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <check-role-dialogs
                :actions="{ type: reoi.enterprise.type.libele, actionText: 'Reply proposal', link: 'proposal.reply', role: 'rfp_reply', title: reoi.title, k: reoi.id }">
              </check-role-dialog> -->
              <v-btn class="secondary" @click="openTab(jobitem.url)">
                <v-icon small class="mr-2">{{ icons.mdiOpenInNew }} </v-icon> Apply
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card tile class="mb-4">
            <v-card-text cols="12" class="">
              <v-divider class="mt-4 mb-6"></v-divider>
              <v-card elevation="0" tile>
                <v-card-title class="subaccent">Job description
                </v-card-title>
                <v-divider></v-divider>
                <v-card-title>{{ jobitem.title }}</v-card-title>
                <v-card-text class="text-justify" v-html="jobitem.description.trim()"></v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text cols="12">
              <v-card-subtitle class="subaccent">
              </v-card-subtitle>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-else>
          <v-skeleton-loader v-bind="attrs" type="card-avatar, article, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mdiAlertCircle, mdiDomain, mdiTargetAccount, mdiCloseCircle, mdiCalendar, mdiKeyboardBackspace, mdiScatterPlot, mdiAlertOutline, mdiMapMarkerOutline, mdiOpenInNew } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { Utils } from '@/plugins/Utils';
import CheckRoleDialog from '@/views/components/CheckRoleDialog.vue';
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import DetailAboutCompany from "@/views/components/company/DetailAboutCompany.vue"


export default {
  components: {
    CheckRoleDialog,
    HeaderPage,
    DetailAboutCompany
  },
  data() {
    return {
      loaderdata: false,
      submit: true,
      absolute: true,
      overlay: false,
      jobitem: {},
      headers: {
        titlepage: 'Job details',
        icon: this.icons.mdiDomain
      },

      loadingRfpEnterprise: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  computed: {
    length() {
      return 7000
    },
  },
  methods: {
    backToPrevious() {
      this.$router.go(-1)
    },
    openTab(link_ref) {
      window.open(link_ref, '_blank');
    },
    loadSingleVacancies() {
      this.loaderdata = true
      // Drequest.api(`external.onceproposal?base=${}&title=${this.$route.params.offer_id}`)

      Drequest.api(`external.oncevacancies`)
        .data({
          "oncevacancies": {
            "keyword": this.$route.params.keyword,
            "link": this.$route.params.offer_title
          }
        })
        .raw((response) => {
          if (response.success === true) {
            this.jobitem = response.datas
            this.loaderdata = false
          }
          else {
            this.loaderdata = false
          }
        })
        .catch((err) => {
          this.loaderdata = false
        });
    }
  },
  beforeMount() {
    this.loadSingleVacancies()
  },
  setup(props) {
    const icons = { mdiAlertCircle, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiDomain, mdiTargetAccount, mdiScatterPlot, mdiCloseCircle, mdiMapMarkerOutline, mdiOpenInNew }

    return {
      icons
    }
  }
}
</script>